import { configureStore } from '@reduxjs/toolkit';
import systemConfigReducer from './system/systemConfigSlice';
import loginUserReducer from './user/loginUserSlice';
import EnabledAddonsReducer from './enabledAddons/enabledAddonsConfigSlice';

import type { SystemConfigStoreInterface } from './system/systemConfigSlice';
import type { UserStoreInterface } from './user/loginUserSlice';
import type { EnabledAddonsStoreInterface } from './enabledAddons/enabledAddonsConfigSlice';
export interface RootState {
  loginUser: UserStoreInterface,
  systemConfig: SystemConfigStoreInterface,
  enabledAddonsConfig: EnabledAddonsStoreInterface,
}
const store = configureStore({
  reducer: {
    loginUser: loginUserReducer,
    systemConfig: systemConfigReducer,
    enabledAddonsConfig: EnabledAddonsReducer,
  },
});

export default store;
