import { createSlice } from '@reduxjs/toolkit';

type SystemConfigStoreInterface = {
  system?: {
    logo?: string;
    url?: {
      api?: string;
      h5?: string;
      pc?: string;
    };
  };
  video?: {
    default_service?: string;
  };
};
const defaultValue: SystemConfigStoreInterface = {

}

const systemConfigSlice = createSlice({
  name: 'systemConfig',
  initialState: defaultValue,
  reducers: {
    saveConfigAction(stage, e) {
      stage.system = e.payload.system;
      stage.video = e.payload.video;
    },
  },
});

export default systemConfigSlice.reducer;
export const { saveConfigAction } = systemConfigSlice.actions;

export type { SystemConfigStoreInterface };
