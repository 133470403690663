import React, { useState, useEffect } from 'react';
import { QRCode, Modal, Button, Spin } from 'antd';
import { system } from '@/api/index';
import styles from './index.module.scss';

type IProps = {
  open: boolean;
  onCancel: () => void;
};

export const StudentDeviceDialog: React.FC<IProps> = ({ open, onCancel }) => {
  const [qrCode, setQrCode] = useState<string>('');
  const [pcUrl, setPcUrl] = useState<string>('');

  useEffect(() => {
    if (open) {
      getData();
    }
  }, [open]);

  const getData = async () => {
    try {
      const res = await system.setting();
      const configData = res.data['系统'];
      for (const index in configData) {
        if (configData[index].key === 'meedu.system.pc_url') {
          const url = configData[index].value;
          setPcUrl(url);
        } else if (configData[index].key === 'meedu.system.h5_url') {
          const h5Url = configData[index].value;
          setQrCode(h5Url);
        }
      }
    } catch (error) {
      console.log('🚀 ~ file: index.tsx:43 ~ getData ~ error:', error);
    }
  };

  return (
    <>
      <Modal
        footer={null}
        title="访问学员端"
        onCancel={() => {
          onCancel();
        }}
        open={open}
        width={500}
        maskClosable={false}
        centered
      >
        <div className={styles['dialog-box']}>
          <div className={styles['dialog-body']}>
            {qrCode === '' ? (
              <div className={styles['qrcode']}>
                <Spin size="large" />
              </div>
            ) : (
              <>
                <QRCode size={150} value={qrCode} />
                <p>H5端扫码访问</p>
              </>
            )}

            <Button type="primary" href={pcUrl}>
              直接访问PC网校
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
