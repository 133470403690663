import type { ColumnsType } from 'antd/es/table';
import { JsonObject } from '@/types';
import { parsePrice } from '@/utils';

const useColumn = (tableType: string): { columns: ColumnsType<JsonObject> } => {
  let columns: ColumnsType<JsonObject>;

  switch (tableType) {
    case 'decoration-vod':
      columns = [
        {
          title: '课程ID',
          width: 120,
          render: (_, record) => <span>{record.id}</span>,
        },
        {
          title: '课程',
          render: (_, record) => (
            <div className="d-flex">
              <div>
                <img src={record.thumb} width="80" height="60" alt={record.title} />
              </div>
              <div className="ml-15">{record.title}</div>
            </div>
          ),
        },
        {
          title: '价格',
          width: 120,
          dataIndex: 'charge',
          render: (charge) => <span>{parsePrice(charge)}</span>,
        },
      ];
      break;
    case 'decoration-topic':
      columns = [
        {
          title: '图文ID',
          width: 120,
          render: (_, record) => <span>{record.id}</span>,
        },
        {
          title: '图文',
          render: (_, record) => (
            <div className="d-flex">
              <div>
                <img src={record.thumb} width="80" height="60" alt={record.title} />
              </div>
              <div className="ml-15">{record.title}</div>
            </div>
          ),
        },
        {
          title: '价格',
          width: 120,
          dataIndex: 'charge',
          render: (charge) => <span>{parsePrice(charge)}</span>,
        },
      ];
      break;
    default:
      throw new Error('tableType must be declared and passed in');
  }

  return { columns };
};

export default useColumn;
