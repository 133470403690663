import { createSlice } from '@reduxjs/toolkit';
import { clearToken } from '@/utils/index';

type UserStoreInterface = {
  user: Record<string, any> | null;
  isLogin: boolean;
  title: string;
};

const defaultValue: UserStoreInterface = {
  user: null,
  isLogin: false,
  title: 'MeEdu后台管理',
};

const loginUserSlice = createSlice({
  name: 'loginUser',
  initialState: defaultValue,
  reducers: {
    loginAction(stage, e) {
      stage.user = e.payload;
      stage.isLogin = true;
    },
    logoutAction(stage) {
      stage.user = null;
      stage.isLogin = false;
      clearToken();
    },
    titleAction(stage, e) {
      stage.title = e.payload;
    },
  },
});

export default loginUserSlice.reducer;
export const { loginAction, logoutAction, titleAction } = loginUserSlice.actions;

export type { UserStoreInterface };
