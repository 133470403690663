import { JsonObject } from '@/types';
import { createSlice } from '@reduxjs/toolkit';

type EnabledAddonsStoreInterface = {
  enabledAddons: JsonObject;
  enabledAddonsCount: number;
};

const defaultValue: EnabledAddonsStoreInterface = {
  enabledAddons: {},
  enabledAddonsCount: 0,
};

const enabledAddonsConfigSlice = createSlice({
  name: 'enabledAddonsConfig',
  initialState: defaultValue,
  reducers: {
    setEnabledAddonsAction(stage, e) {
      stage.enabledAddons = e.payload.addons;
      stage.enabledAddonsCount = e.payload.count;
    },
  },
});

export default enabledAddonsConfigSlice.reducer;
export const { setEnabledAddonsAction } = enabledAddonsConfigSlice.actions;

export type { EnabledAddonsStoreInterface };
