import { useNavigate, useRoutes } from 'react-router-dom';
import routes from './routes';
import './App.scss';
import './scss/main.scss';
import { Suspense, useEffect, useState } from 'react';
import LoadingPage from './pages/loading';
import './i18n/config';
import { getToken } from './utils';
import { login, system } from './api';
import { loginAction } from './store/user/loginUserSlice';
import { SystemConfigStoreInterface, saveConfigAction } from './store/system/systemConfigSlice';
import { setEnabledAddonsAction } from './store/enabledAddons/enabledAddonsConfigSlice';
import { useDispatch } from 'react-redux';
import { JsonObject } from './types';
import { ErrorBoundary } from 'react-error-boundary';
function App() {
  const navigate = useNavigate();
  const Views = () => useRoutes(routes);
  const dispatch = useDispatch();
  const [init, setInit] = useState<boolean>(false);

  useEffect(() => {
    if (!getToken()) {
      navigate('/login');
    } else {
      initData();
    }
  }, []);

  const initData = async () => {
    try {
      setInit(true);

      const configRes = await system.getSystemConfig();
      const userRes = await login.getUser();
      const addonsRes = await system.addonsList();
      if (userRes.data) {
        dispatch(loginAction(userRes.data));
      }

      if (configRes.data) {
        const config: SystemConfigStoreInterface = {
          system: {
            logo: configRes.data.system.logo,
            url: {
              api: configRes.data.system.url.api,
              h5: configRes.data.system.url.h5,
              pc: configRes.data.system.url.pc,
            },
          },
          video: {
            default_service: configRes.data.video.default_service,
          },
        };
        dispatch(saveConfigAction(config));
      }

      if (addonsRes.data) {
        const enabledAddons: JsonObject = {};
        let count = 0;
        for (let i = 0; i < addonsRes.data.length; i++) {
          if (addonsRes.data[i].enabled) {
            count += 1;
            enabledAddons[addonsRes.data[i].sign] = 1;
          }
        }
        dispatch(setEnabledAddonsAction({ addons: enabledAddons, count: count }));
      }
    } catch (error) {
      console.log('🚀 ~ file: App.tsx:67 ~ initData ~ error:', error);
    } finally {
      setInit(false);
    }
  };

  return (
    <ErrorBoundary
      onError={(error) => {
        console.log('🚀 ~ file: App.tsx:81 ~ App ~ error:', error);
      }}
      fallbackRender={({ error, resetErrorBoundary }) => {
        return (
          <div role="alert">
            <p>Something went wrong:</p>
            <pre style={{ color: 'red' }}>{error.message}</pre>
            <button onClick={resetErrorBoundary} style={{ color: 'black' }}>
              try again
            </button>
          </div>
        );
      }}
    >
      <Suspense fallback={<LoadingPage />}>{init ? <LoadingPage /> : <Views />}</Suspense>
    </ErrorBoundary>
  );
}

export default App;
