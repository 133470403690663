import { createContext, useContext } from 'react';
import { DataType } from '@/types';

interface Resources {
  selectedRowKeys: React.Key[];
  setSelectedRowKeys: (value: React.Key[]) => void;
  resourceActive: string;
  list: DataType[];
  setList: (value: DataType[]) => void;
  total: number;
  setTotal: (value: number) => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
}

export const ResourcesContext = createContext<Resources | undefined>(undefined);

export function useResourcesContext() {
  const resources = useContext(ResourcesContext);

  if (resources === undefined) {
    throw new Error('useResourcesContext must be used with a ResourcesContext');
  }

  return resources;
}
