import { use } from 'i18next';
import { initReactI18next } from 'react-i18next';
import sc from './locale/sc.json';
import en from './locale/en.json';

use(initReactI18next).init({
    lng: 'sc', // if you're using a language detector, do not define the lng option
    debug: true,
    resources: {
        sc: { translation: sc  },
        en: { translation: en  },
    },
    // if you see an error like: "Argument of type 'DefaultTFuncReturn' is not assignable to parameter of type xyz"
    // set returnNull to false (and also in the i18next.d.ts options)
    // returnNull: false,
});

