import { useEffect, useState, memo } from 'react';
import { Table, Input, Button } from 'antd';
import { useResourcesContext } from '../context';
import { course, topic } from '@/api/index';
import { SortingOrder } from '@/enum';
import { JsonObject, TablePaginationPosition } from '@/types';
import useColumn from '@/hooks/useColumn';
import styles from '../index.module.scss';

const TypeSelectionTable = () => {
  const { selectedRowKeys, setSelectedRowKeys, resourceActive, loading, setLoading, list, setList, total, setTotal } =
    useResourcesContext();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [refresh, setRefresh] = useState(false);
  const [keywords, setKeywords] = useState<string>('');

  useEffect(() => {
    getData();
  }, [page, size, refresh, resourceActive]);

  const { columns } = useColumn('decoration-' + resourceActive);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fetchData = async (apiFunction: (params: any) => Promise<JsonObject>, type?: string) => {
    return await apiFunction({
      page,
      size,
      sort: 'created_at',
      order: SortingOrder.DESC,
      keywords: type === 'reset' ? '' : keywords,
      is_show: 1,
    });
  };

  const getData = async (type?: string) => {
    if (loading) {
      return;
    }

    try {
      setLoading(true);
      if (resourceActive === 'vod') {
        const res = await fetchData(course.list, type);
        setList(res.data.courses.data);
        setTotal(res.data.courses.total);
      } else if (resourceActive === 'topic') {
        const res = await fetchData(topic.list, type);
        setList(res.data.data.data);
        setTotal(res.data.data.total);
      } else {
        throw '🚀 ~ Type is not declared here';
      }
    } catch (error) {
      console.log('🚀 ~ getData ~ error:', error);
    } finally {
      setLoading(false);
    }
  };

  const resetList = () => {
    setPage(1);
    setSize(10);
    setKeywords('');
    setRefresh(!refresh);
    if (keywords) {
      getData('reset');
    }
  };

  const paginationProps = {
    current: page, //当前页码
    pageSize: size,
    total: total, // 总条数
    onChange: (page: number, pageSize: number) => handlePageChange(page, pageSize), //改变页码的函数
    showSizeChanger: true,
    position: ['bottomCenter'] as TablePaginationPosition[],
  };

  const handlePageChange = (page: number, pageSize: number) => {
    setPage(page);
    setSize(pageSize);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  return (
    <div className={styles['typeSelection__wrapper']}>
      <div className={styles['typeSelection__search']}>
        <Input
          value={keywords}
          onChange={(e) => {
            setKeywords(e.target.value);
          }}
          placeholder="关键字"
          allowClear
        />
        <Button onClick={resetList}>清空</Button>
        <Button
          type="primary"
          onClick={() => {
            setPage(1);
            setRefresh(!refresh);
          }}
        >
          筛选
        </Button>
      </div>

      <Table
        scroll={{ y: 400 }}
        loading={loading}
        columns={columns}
        rowSelection={rowSelection}
        dataSource={list}
        rowKey={(record) => record.id}
        pagination={paginationProps}
      />
    </div>
  );
};

export default memo(TypeSelectionTable);
