import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import 'dayjs/locale/zh-cn';
import App from './App';
import './index.scss'; //全局样式
import AutoScorllTop from './AutoTop';
// import * as Sentry from '@sentry/react';
import Mask from './components/Mask';

// User Agent Detection
const userAgent = navigator.userAgent;
const needsMask = /MicroMessenger|QQBrowser/.test(userAgent);
//disable sentry in localhost
// if (import.meta.env.VITE_ENVIRONMENT) {
//   const sentryLink = `${window.location.protocol}//${import.meta.env.VITE_SENTRY_PROKEY}@${
//     window.location.host
//   }/sentry/${import.meta.env.VITE_SENTRY_ID}`;

//   Sentry.init({
//     dsn: sentryLink,
//     integrations: [new Sentry.BrowserTracing()],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//     // 环境变量
//     environment: import.meta.env.VITE_ENVIRONMENT,
//     release: import.meta.env.VITE_TAG, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   });
// }

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Provider store={store}>
    <ConfigProvider locale={zhCN} theme={{ token: { colorPrimary: '#3ca7fa' } }}>
      <BrowserRouter>
        <AutoScorllTop>{needsMask ? <Mask /> : <App />}</AutoScorllTop>
      </BrowserRouter>
    </ConfigProvider>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
