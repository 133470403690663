import React from 'react';
import styles from './index.module.scss';

const Mask = () => {
  return (
    <div className={styles['main']}>
      <div className={styles['qqmask']}>
        <div></div>
      </div>
    </div>
  );
};

export default Mask;
